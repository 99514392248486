<template>
  <v-card>
    <v-snackbar color="red" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">Reporte de Tipos de Tarjetas</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        v-if="!loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    rounded
                    filled
                    :rules="[rules.required]"
                    label="Seleccionar mes"
                    prepend-icon="fas fa-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="$refs.menu.save(date)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="py-0 my-0">
              <v-select
                rounded
                :rules="[rules.required]"
                v-model="bankId"
                filled
                item-text="name"
                label="Seleccionar banco"
                item-value=".key"
                :items="banks"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn
                rounded
                :loading="loading"
                @click="generateReport"
                height="55"
                color="primary"
                block
              >
                Generar reporte
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

export default {
  name: "orders-by-user",
  props: ["type"],
  components: {},
  data() {
    return {
      menu: false,
      snackbar: false,
      snackbarText: "",
      date: null,
      loading: false,
      banks: [],
      bankId: "",
      rules: {
        required: (value) => !!value || "Obligatorio",
      },
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async generateReport() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }
      this.loading = true;

      let token = await this.getSectionToken();

      let payload = {
        dateStart: moment(this.date, "YYYY-MM")
          .startOf("month")
          .format("DD-MM-YYYY"),
        dateEnd: moment(this.date, "YYYY-MM")
          .endOf("month")
          .format("DD-MM-YYYY"),
        bankId: this.bankId,
      };

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/httpReportsCardholders`,
          payload,
          {
            headers: { Authorization: `Basic ${token}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.loading = false;
          this.$emit("cancel");

          const { data, headers } = response;
          const fileName = `Reporte de Tipos de Tarjetas ${payload.dateStart}-${payload.dateEnd}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {
    this.$binding("banks", db.collection("banks").orderBy("name"));
  },
};
</script>
