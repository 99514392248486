<template>
  <v-card>
    <v-snackbar color="red" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">Reporte de cancelación de órdenes</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        v-if="!loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Seleccionar rango de fechas"
                    prepend-inner-icon="fas fa-calendar"
                    readonly
                    :rules="[rules.required]"
                    rounded
                    clearable
                    filled
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker range v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                rounded
                multiple
                filled
                item-text="name"
                label="Seleccionar motivo de cancelación"
                item-value=".key"
                v-model="cancellationIds"
                persistent-hint
                :items="reasons"
                hint="No seleccionar motivo si desea obtener todas las órdenes canceladas."
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn
                rounded
                :loading="loading"
                @click="generateReport"
                height="55"
                color="primary"
                block
              >
                Generar reporte
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

export default {
  name: "orders-by-user",
  props: ["type"],
  components: {},
  data() {
    return {
      menu: false,
      snackbar: false,
      snackbarText: "",
      menu: null,
      cancellationIds: [],
      reasons: [],
      loading: false,
      date: [],
      rules: {
        required: (value) => !!value.length || "Obligatorio",
      },
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async generateReport() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }

      if (this.date.length != 2) {
        this.snackbarText = "Ingrese un rango de fechas válido.";
        this.snackbar = true;
        return;
      }

        this.loading = true;

      let token = await this.getSectionToken();

      let payload = {
        startDate: moment(this.date[0], "YYYY-MM-DD").format("DD-MM-YYYY"),
        endDate: moment(this.date[1], "YYYY-MM-DD").format("DD-MM-YYYY"),
        rejectedReasonIds: this.cancellationIds,
      };
      
      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/httpReportsCanceledOrders`,
          payload,
          {
            headers: { Authorization: `Basic ${token}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.loading = false;
          this.$emit("cancel");

          const { data, headers } = response;
          const fileName = `Reporte de órdenes canceladas ${payload.startDate}-${payload.endDate}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {
    await this.$binding(
      "reasons",
      db
        .collection("cancellationReasons")
        .where("active", "==", true)
        .where("deleted", "==", false)
        .orderBy("name", "asc")
    );
  },
};
</script>
  