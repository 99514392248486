<template>
    <div>
      <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
        snackbarText
      }}</v-snackbar>
  
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">Planilla motoristas</span>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            v-if="!loading"
            absolute
            @click="$emit('cancel')"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Seleccionar rango de fechas"
                      prepend-inner-icon="fas fa-calendar"
                      readonly
                      rounded
                      clearable
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker range v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      Aceptar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
  
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  :disabled="!user && type == 'user'"
                  rounded
                  :loading="loading"
                  @click="generateReport"
                  height="55"
                  color="primary"
                  block
                >
                  Generar reporte
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  import { db, fb } from "@/firebase";
  import moment from "moment-timezone";
  import "moment/locale/es";
  moment.locale("es");
  import axios from "axios";
  
  export default {
    name: "drivers-payroll",
    props: ["type"],
    components: {},
    data() {
      return {
        menu: false,
        snackbar: false,
        snackbarText: "",
        date: [],
        loading: false,
        user: null,
      };
    },
  
    methods: {
      getSectionToken() {
        return new Promise((resolve, reject) => {
          fb.auth()
            .currentUser.getIdToken(true)
            .then((token) => {
              resolve(token);
            });
        });
      },
  
      async generateReport() {
        if (!this.type && (!this.date[0] || !this.date[1])) {
          this.snackbarText = "Debe seleccionar un rango de fechas.";
          this.snackbar = true;
          return;
        }
        this.loading = true;
  
        let token = await this.getSectionToken();
  
        let data = {};
  
        if (this.date[0] && this.date[1]) {
          data.startDate = moment(this.date[0], "YYYY-MM-DD").format("DD-MM-YYYY")
          data.endDate = moment(this.date[1], "YYYY-MM-DD").format("DD-MM-YYYY")
        }
  
        if (this.user) {
          data.userId = this.user.id;
        }
  
        /*var gteDataKM = fb
          .functions()
          .httpsCallable("httpDiloGetDataKm");
        gteDataKM(data)*/
        axios
          .post(
            `${process.env.VUE_APP_FUNCTIONS_URL}/httpDiloGetDataKm`,
            data,
            {
              headers: { Authorization: `Basic ${token}` },
              responseType: "blob",
            }
          )
          .then((response) => {
            this.loading = false;
            this.$emit("cancel");
  
            const { data, headers } = response;
            const fileName = `Planilla de  ${
              this.user ? this.user.name : "motoristas"
            }.xlsx`;
  
            const blob = new Blob([data], { type: headers["content-type"] });
            let dom = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            dom.href = url;
            dom.download = decodeURI(fileName);
            dom.style.display = "none";
            document.body.appendChild(dom);
            dom.click();
            dom.parentNode.removeChild(dom);
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      },
    },
  
    //async mounted() {},
  };
  </script>
  