<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Reporte de productos {{ type == "user" ? "por usuarios" : "" }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          v-if="!loading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col v-if="type == 'user'" cols="12" sm="10" md="10">
              <v-text-field
                rounded
                hide-details
                filled
                v-on:keyup.enter="searchUser"
                v-model="phone"
                label="Ingrese el número de teléfono"
                placeholder="+50496771316"
              >
              </v-text-field>
            </v-col>

            <v-col v-if="type == 'user'" class="mt-2" cols="12" sm="2" md="2">
              <v-btn :loading="searching" @click="searchUser" color="primary">
                <v-icon> fas fa-search </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Seleccionar rango de fechas"
                    prepend-inner-icon="fas fa-calendar"
                    readonly
                    rounded
                    clearable
                    filled
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker range v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col v-if="user" cols="12" sm="12" md="12">
              <small>Usuario encontrado</small>
              <div
                style="border: 1px solid green; background-color: #80808021"
                class="pa-3"
              >
                <p class="font-weight-bold">
                  Nombre: {{ user.name }} {{ user.surname }}
                </p>
                <p class="font-weight-bold" v-if="user.email">
                  Email: {{ user.email }}
                </p>

                <p class="font-weight-bold" v-if="user.email">
                  Cantidad de órdenes: {{ user.orderCounter }}
                </p>

                <p class="font-weight-bold" v-if="user.email">
                  Fecha de registro: {{ user.created | filterDate }}
                </p>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn
                :disabled="!user && type == 'user'"
                rounded
                :loading="loading"
                @click="generateReport"
                height="55"
                color="primary"
                block
              >
                Generar reporte
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

export default {
  name: "orders-by-user",
  props: ["type"],
  components: {},
  data() {
    return {
      menu: false,
      snackbar: false,
      snackbarText: "",
      date: [],
      phone: "",
      loading: false,
      user: null,
      searching: false,
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async searchUser() {
      if (this.phone) {
        this.searching = true;

        let users = await db
          .collection("users")
          .where("phone", "==", this.phone)
          .get();

        if (users.size) {
          this.user = users.docs[0].data();
          this.user.id = users.docs[0].id;
        } else {
          this.snackbarText = "Usuario no encontrado.";
          this.snackbar = true;
        }

        this.searching = false;
      }
    },

    async generateReport() {
      if (!this.type && (!this.date[0] || !this.date[1])) {
        this.snackbarText = "Debe seleccionar un rango de fechas.";
        this.snackbar = true;
        return;
      }
      this.loading = true;

      let token = await this.getSectionToken();

      let data = {};

      if (this.date[0] && this.date[1]) {
        data.startDate = this.date[0];
        data.endDate = this.date[1];
      }

      if (this.user) {
        data.userId = this.user.id;
      }

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/httpReportsProducts`,
          data,
          {
            headers: { Authorization: `Basic ${token}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.loading = false;
          this.$emit("cancel");

          const { data, headers } = response;
          const fileName = `Reporte de  ${
            this.user ? this.user.name : "productos"
          }.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {},
};
</script>
