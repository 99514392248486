<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Otros reportes</h1>
      </v-col>
    </v-row>

    <v-row class="pa-0 ml-10">
      <v-col cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Productos comprados por usuario</h2>

          <p class="mb-5">
            Esta opción le permite descargar un reporte de productos comprados
            por un usuario específico en un rango de fechas establecidas.
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn
              @click="
                ordersByUsersDialog = true;
                type = 'user';
              "
              rounded
              color="primary"
            >
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Reporte de productos comprados</h2>

          <p class="mb-5">
            Esta opción le permite descargar un reporte de productos comprados
            en un rango de fechas establecidas.
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn
              @click="
                ordersByUsersDialog = true;
                type = null;
              "
              rounded
              color="primary"
            >
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col v-if="sellReportUsers.includes(user.type)" cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Reporte de ventas</h2>

          <p class="mb-5">
            Esta opción le permite descargar un reporte completo de ventas en un
            rango de fechas establecidas.
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn
              @click="
                ordersReportDialog = true;
                type = null;
              "
              rounded
              color="primary"
            >
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col v-if="sellReportUsers.includes(user.type)" cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Reporte de Tipos de Tarjetas</h2>

          <p class="mb-5">
            Esta opción le permite descargar un reporte de tarjetahabientes del
            banco seleccionado.
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn
              @click="
                cardholderDialog = true;
                type = null;
              "
              rounded
              color="primary"
            >
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col v-if="sellReportUsers.includes(user.type)" cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Reporte de Odoo</h2>

          <p class="mb-5">
            Esta opción le permite descargar un reporte para cargar en Odoo
            CRM/ERP.
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn @click="odooDialog = true" rounded color="primary">
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col v-if="sellReportUsers.includes(user.type)" cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Reporte de cancelación de órdenes</h2>

          <p class="mb-5">
            Esta opción le permite descargar un listado de órdenes canceladas,
            así mismo filtrar por tipo de cancelación
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn @click="cancellationDialog = true" rounded color="primary">
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col v-if="sellReportUsers.includes(user.type)" cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Planilla de conductores</h2>

          <p class="mb-5">
            Esta opción le permite descargar la planilla de conductores
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn @click="driverPayrollDialog = true" rounded color="primary">
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col v-if="sellReportUsers.includes(user.type)" cols="12" sm="6" md="6">
        <div
          class="pa-5"
          style="background-color: #a9a9a929; min-height: 210px"
        >
          <h2 class="font-weight-bold">Reporte de Ocho Cargo</h2>

          <p class="mb-5">
            Esta opción le permite descargar lista de mandados generados en Ocho
            Cargo
          </p>

          <div style="display: flex; justify-content: flex-end; width: 100%">
            <v-btn @click="cargoDialog = true" rounded color="primary">
              <v-icon x-large class="mr-5" dark>fas fa-file-excel</v-icon
              >Generar</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-if="ordersByUsersDialog"
      persistent
      v-model="ordersByUsersDialog"
      max-width="650px"
    >
      <orders-by-user :type="type" @cancel="ordersByUsersDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="driverPayrollDialog"
      persistent
      v-model="driverPayrollDialog"
      max-width="650px"
    >
      <drivers-payroll :type="type" @cancel="driverPayrollDialog = false" />
    </v-dialog>

    <orders-report
      v-if="ordersReportDialog"
      @success="ordersReportDialog = false"
      @cancel="ordersReportDialog = false"
      :type="type"
    />

    <v-dialog
      v-if="cardholderDialog"
      persistent
      v-model="cardholderDialog"
      max-width="650px"
    >
      <cardholders :type="type" @cancel="cardholderDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="odooDialog"
      persistent
      v-model="odooDialog"
      max-width="650px"
    >
      <odoo :type="type" @cancel="odooDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="cancellationDialog"
      persistent
      v-model="cancellationDialog"
      max-width="650px"
    >
      <cancellation :type="type" @cancel="cancellationDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="cargoDialog"
      persistent
      v-model="cargoDialog"
      max-width="650px"
    >
      <cargo-report :type="type" @cancel="cargoDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import ordersByUser from "./ordersByUsers.vue";
import ordersReport from "../orders-reports/new-report";
import { mapState } from "vuex";
import cardholders from "./cardholders";
import odoo from "./odoo.vue";
import cancellation from "./cancellations.vue";
import driversPayroll from "./driversPayroll.vue";
import cargoReport from "../../sudo/corporative-errands/menu/errands/report.vue";

export default {
  name: "other-reports",
  components: {
    ordersByUser,
    ordersReport,
    cardholders,
    odoo,
    cancellation,
    driversPayroll,
    cargoReport,
  },
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      cargoDialog: false,
      ordersByUsersDialog: false,
      ordersReportDialog: false,
      cardholderDialog: false,
      cancellationDialog: false,
      driverPayrollDialog: false,
      odooDialog: false,
      type: "",
      sellReportUsers: ["sudo", "admin", "EFCM", "ACC", "AC"],
      timesReport: ["sudo", "admin"],
      // timesDialog : false
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {},
  mounted() {},
};
</script>
